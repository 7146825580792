import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { useTranslation } from 'react-i18next';
import readXlsxFile from 'read-excel-file';

import {
  MenuItem,
  Typography,
  Grid,
  Divider,
  Button,
  Stack,
  CardContent,
  Tab,
  Box,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Tooltip,
  LinearProgress,
  Snackbar,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

import {
  IconArrowsSort,
  IconCalendarEvent,
  IconClockHour5,
  IconFile,
  IconFlag3,
  IconHeadphones,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

import {
  CampaignDto,
  CampaignRunRecipientStatusType,
  useCreateCampaignRunControllerCreateCampaignRunMutation,
  useGetAllAgentsControllerGetAllAgentsQuery,
  useStopCampaignRunControllerStopCampaignRunMutation,
  CallScheduleRequestDto,
  LeadListSimpleDto,
  useGetCampaignControllerGetCampaignQuery,
  GetRecipientConversationControllerGetCampaignApiResponse,
  useDeleteCampaignControllerDeleteCampaignMutation,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
} from '@shared/services/apiService/apiService';
import Spinner from '@shared/components/spinner/Spinner';
import { MonitorCampaignPopup } from '@widgets/Popup/MonitorCampaign/MonitorCampaign';
import { SchedulePopup } from '@widgets/Popup/Schedule/Schedule';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import CustomFormLabel from '@shared/components/forms/theme-elements/CustomFormLabel';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import BlankCard from '@shared/components/shared/BlankCard';

import { useStyles } from './styles';
import { capitalize } from 'lodash';
import CampaignStatusButton, {
  CampaignStatus,
} from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton';
import { useEditCampaign } from '@features/Admin/Campaign/hooks/useEditCampaign';
import { FormFields } from '@shared/types/admin/campaign';
import { initialSchedule } from './CreateCampaign';
import { useGetRecipient } from '@features/Admin/Campaign/hooks/useGetRecipient';
import { getCallDuration } from './utils';
import { ConfirmDeleteDialog } from '@shared/components/confirmDeleteDialog/ConfirmDeleteDialog';
import { CampaignTextField } from '@shared/components/campaignTextField/campaignTextField';
import CustomAlert from '@shared/components/alert/alert';
import * as XLSX from 'xlsx';
import { useCampaignLogic } from '@features/Admin/Campaign/hooks/useCampaignLogic';
import parsePhoneNumberFromString from 'libphonenumber-js';

type Column = {
  [key: string]: any;
};

type FileData = {
  id: number;
  fileName: string;
  columns: Column;
};

type Call = {
  id: string;
  phoneNumber: string;
  startDate: string;
  callDuration: string;
  sentiments: any[];
  status: CampaignRunRecipientStatusType;
};

type SortConfig = {
  key: keyof Call | null;
  direction: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const EditCampaignPage: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();

  const [campaignId, setCampaignId] = useState<string | null>();
  const [campaign, setCampaign] = useState<CampaignDto | null>();

  const [recipients, setRecipient] =
    useState<GetRecipientConversationControllerGetCampaignApiResponse | null>();

  const { recipientData, recipientRefetch, pollingInterval, setPollingInterval } = useGetRecipient(
    campaignId as string,
  );

  const [useRunCampaign, { isLoading: isRunningLoading }] =
    useCreateCampaignRunControllerCreateCampaignRunMutation();

  const [useStopCampaign, { isLoading: isStoppingLoading }] =
    useStopCampaignRunControllerStopCampaignRunMutation();

  const {
    onSubmit,
    setValue,
    control,
    reset,
    responseLoadingFlag,
    editCampaignError,
    setEditCampaignError,
    excelFieldError,
    setExcelFieldError,
  } = useEditCampaign();

  const {
    inputFilesNameStorage,
    setInputFilesNameStorage,
    inputFile,
    fileError,
    listError,
    handleAddFileData,
    setListError,
    setFileError,
  } = useCampaignLogic();

  const {
    data: campaignData,
    refetch,
    isLoading,
  } = useGetCampaignControllerGetCampaignQuery(
    { id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  );

  const [useDeleteCampaign] = useDeleteCampaignControllerDeleteCampaignMutation();

  const { refetch: refetchAllCampaigns } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const { data: agentList, error } = useGetAllAgentsControllerGetAllAgentsQuery();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setCampaignId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (campaignId) {
      refetch().then((r) => {
        setCampaign(r.data);
      });

      recipientRefetch().then((r) => {
        setRecipient(r.data);
      });
    }
  }, [campaignId, refetch, recipientRefetch]);

  const BCrumb = [
    {
      to: AuthenticatedPath.CAMPAIGN,
      title: t('campaignPage.header.campaign'),
    },
    {
      title: t('action.edit'),
    },
  ];

  const [tabIdx, setTabIdx] = React.useState(0);
  const [calls, setCalls] = useState<Call[]>([]);
  const [showMonitorDialogFlag, setShowMonitorDialogFlag] = useState(false);
  const [showScheduleDilaogFlag, setShowScheduleDialogFlag] = useState(false);
  const [listDeleteDialog, setListDeleteDialog] = useState(false);
  const [selectedListIdx, setListIdx] = useState<number | null>();
  const [fileDataArray, setFileDataArray] = useState<FileData[]>([]);
  const [fileUploadingProgress, setfileUploadingProgress] = useState(0);
  const [countOfPhoneNumbers, setCountOfPhoneNumbers] = useState(0);
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const [fileNameToDelete, setFileNameToDelete] = useState('');
  const [numbersLists, setNumbersLists] = useState<LeadListSimpleDto[]>([]);
  const [schedule, setSchedule] = useState<CallScheduleRequestDto>(initialSchedule);
  const [agent, setAgent] = useState('');
  const [agentName, setAgentName] = useState('');
  const [templateTextFieldValue, setTemplateTextFieldValue] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignNameError, setCampaignNameError] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [retryMaxNumberValue, setRetryMaxNumberValue] = useState('');
  const [retriesPerDayValue, setRetriesPerDayValue] = useState('');
  const [retryIntervalInMinutesValue, setRetryIntervalInMinutesValue] = useState('');
  const [retriesPerDayError, setRetriesPerDayError] = useState('');
  const [retryMaxNumberError, setRetryMaxNumberError] = useState('');
  const [retryIntervalInMinutesError, setRetryIntervalInMinutesError] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      recipientRefetch()
        .then((r) => {
          setRecipient(r.data);
          setPollingInterval(1000);
        })
        .catch(() => {});
    }, pollingInterval);

    return () => clearInterval(intervalId);
  }, [recipientRefetch, pollingInterval]);

  useEffect(() => {
    if (recipients) {
      const mappedRecipients = recipients?.map(
        ({ leadId, status, sentiments, answeredAt, terminatedAt, lead: { phone }, startedAt }) => ({
          id: leadId,
          sentiments,
          status,
          phoneNumber: phone,
          startDate: startedAt as string,
          callDuration:
            startedAt && answeredAt && terminatedAt
              ? getCallDuration(answeredAt, terminatedAt)
              : null,
        }),
      ) as Call[];

      setCalls(mappedRecipients as Call[]);
    }
  }, [recipients]);

  useEffect(() => {
    if (agentList && campaignData && campaignData.callSchedule) {
      setIsFile(true);

      const modifiedLeadList = campaignData.leadList.map((list) => ({
        name: list.name,
        leads: list.leads.map((lead) => ({ phone: lead.phone })),
      }));

      for (let i = 0; i < modifiedLeadList.length; i++) {
        if (!inputFilesNameStorage.hasOwnProperty(modifiedLeadList[i].name)) {
          inputFilesNameStorage[modifiedLeadList[i].name] = modifiedLeadList[i].name;
        }
      }

      const { leadList, ...newObj } = campaignData;
      const updatedСampaignData = {
        ...newObj,
        leadList: modifiedLeadList,
      };

      const newFileDataArray = modifiedLeadList.map((item, index) => ({
        id: index,
        fileName: item.name,
        columns: {
          phone: item.leads.map((lead) => lead.phone),
        },
      }));

      setFileDataArray(newFileDataArray);

      reset(updatedСampaignData);

      let currentAgent = agentList.find((agent) => agent.id == campaignData.agentId);
      if (currentAgent) {
        setAgent(currentAgent.id);
        setAgentName(currentAgent.mission.humanName);
      }

      setCampaignName(campaignData.title);
      setRetryMaxNumberValue(campaignData.retryMaxNumber.toString());
      setRetriesPerDayValue(campaignData.retriesPerDay.toString());
      setRetryIntervalInMinutesValue(campaignData.retryIntervalInMinutes.toString());
      if (campaignData.followUpTemplate) {
        setTemplateTextFieldValue(campaignData.followUpTemplate);
      }
      setSchedule(campaignData.callSchedule);
    }
    if (error) {
      console.error('Error fetching agents:', error);
    }
  }, [agentList, error, campaignData, reset]);

  useEffect(() => {
    setValue('callSchedule', schedule);
  }, [schedule]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfileUploadingProgress(10);
    const file = e.target.files && e.target.files[0];

    let fileName = file?.name;
    if (fileName) {
      if (fileName in inputFilesNameStorage) {
        setfileUploadingProgress(0);
        return;
      } else {
        setInputFilesNameStorage((prevState) => ({ ...prevState, [fileName!]: fileName! }));
      }
    }

    if (!file) {
      return;
    }

    setIsFile(true);

    setfileUploadingProgress(80);

    readXlsxFile(file).then((rows: Column) => {
      if (rows[0][0] !== 'phone') {
        setListError('Column name should be "phone"');
        setfileUploadingProgress(0);
        return;
      }
      const columnNames = rows[0] as string[];
      let columns: Column = {};

      setfileUploadingProgress(60);

      columnNames.forEach((name) => {
        columns[name] = [];
      });

      for (let i = 1; i < rows.length; i++) {
        let row = rows[i];
        for (let j = 0; j < row.length; j++) {
          let cell = row[j];
          let columnName = columnNames[j];

          const phoneNumber = String(cell || '').trim();

          if (columnName && columnName.toLowerCase() === 'phone' && phoneNumber) {
            const parsedPhoneNumber = parsePhoneNumberFromString(
              phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`,
            );

            columns[columnName].push(parsedPhoneNumber?.format('E.164'));

            setCountOfPhoneNumbers(columns[columnName].length);
          }
        }
      }

      const fileData: FileData = {
        id: fileDataArray.length,
        fileName: file.name,
        columns: columns,
      };

      setfileUploadingProgress(100);
      setFileDataArray((prev) => [...prev, fileData]);

      setTimeout(() => {
        setfileUploadingProgress(0);
      }, 500);
      setSnackBarShow(true);
    });

    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };

  const onDragDropEnds = (oldIndex: number | undefined, newIndex: number | undefined) => {
    if (typeof oldIndex === 'number' && typeof newIndex === 'number') {
      const newArray = [...fileDataArray];

      const [removedItem] = newArray.splice(oldIndex, 1);

      newArray.splice(newIndex, 0, removedItem);
      const updatedArray = newArray.map((item, index) => ({ ...item, id: index }));

      setFileDataArray(updatedArray);
    }
  };

  const handleDeleteList = (id: number, fileName: string) => {
    setListDeleteDialog(true);
    setListIdx(id);
    if (Object.keys(inputFilesNameStorage).length == 0) {
      setIsFile(false);
    }
    setFileNameToDelete(fileName);
    setFileError('');
    setExcelFieldError('');
  };

  const deletFileData = () => {
    for (let key in inputFilesNameStorage) {
      if (inputFilesNameStorage[key] == fileNameToDelete) {
        setInputFilesNameStorage((prevState) => {
          const newState = { ...prevState };
          delete newState[fileNameToDelete];
          return newState;
        });
        setFileNameToDelete('');
      }
    }

    if (selectedListIdx === null) {
      return;
    }
    const updatedList = fileDataArray.filter((item) => item.id !== selectedListIdx);
    const updatedIDsList = updatedList.map((item) => {
      return selectedListIdx && item.id > selectedListIdx ? { ...item, id: item.id - 1 } : item;
    });
    setFileDataArray(updatedIDsList);
    setListDeleteDialog(false);

    if (!fileDataArray) {
      setListIdx(null);
    }
  };

  const closeDialog = () => {
    setShowMonitorDialogFlag(false);
    setShowScheduleDialogFlag(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIdx(newValue);
  };

  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'asc' });

  const handleSort = (key: keyof Call) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedCalls = [...calls].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setCalls(sortedCalls);
    setSortConfig({ key, direction });
  };

  const handleSortPhoneNumber = () => handleSort('phoneNumber');
  const handleSortDate = () => handleSort('startDate');
  const handleSortDuration = () => handleSort('callDuration');
  const handleSortSentiment = () => {
    // handleSort('sentiments');
  };

  const handleManagedStartStop = async (id: string, campaignStatus: CampaignStatus) => {
    if (CampaignStatus.NotStarted === campaignStatus) {
      await useRunCampaign({ id });
    } else if (CampaignStatus.Dialing === campaignStatus) {
      await useStopCampaign({ id });
    }

    refetch();
  };

  useEffect(() => {
    const newNumbersLists = fileDataArray.map((fileData) => ({
      name: fileData.fileName,
      leads: fileData.columns.phone.map((phone: { toString: () => any }) => ({
        phone: phone.toString(),
      })),
    }));

    setValue('leadList', newNumbersLists);

    setNumbersLists(newNumbersLists);
  }, [fileDataArray]);

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name == 'retryMaxNumber' || name == 'retriesPerDay' || name == 'retryIntervalInMinutes') {
      const filteredValue = value.replace(/[^0-9.]/g, '');
      const parsedValue = parseFloat(filteredValue);

      if (name == 'retryMaxNumber') {
        setRetryMaxNumberValue(filteredValue);
        setRetryMaxNumberError('');
        setValue(name as FormFields, parsedValue);
      }

      if (name == 'retriesPerDay') {
        setRetriesPerDayValue(filteredValue);
        setRetriesPerDayError('');
        setValue(name as FormFields, parsedValue);
      }

      if (name == 'retryIntervalInMinutes') {
        setRetryIntervalInMinutesValue(filteredValue);
        setRetryIntervalInMinutesError('');
        setValue(name as FormFields, parsedValue);
      }
    } else {
      setValue(name as FormFields, value);
    }

    if (name == 'title') {
      setCampaignName(value);
    }
  };

  const handleAgentChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setAgent(value as string);
    let chosenAgent = agentList?.filter((currentAgent) => currentAgent.id == value);
    if (chosenAgent) {
      setAgentName(chosenAgent[0].mission.humanName);
    }
    setValue(name as FormFields, value);
  };

  const handleDeleteBtn = (id: any) => {
    setShowDialog(true);
    setDeleteId(id);
  };

  const deleteCampagin = async () => {
    if (deleteId) {
      setShowDialog(false);
      setLoadingFlag(true);
      const response = await useDeleteCampaign({
        id: deleteId,
      });
      if ('error' in response) {
        throw response.error;
      } else {
        await refetchAllCampaigns();
        navigator('/');
      }
    }
    setDeleteId('');
    setLoadingFlag(false);
  };

  const downloadExcel = (fileName: string, data: number[]) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      data.map((phone) => ({ phone })),
      { header: ['phone'] },
    );
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  };

  const handleDownload = (fileName: string, data: number[]) => {
    downloadExcel(fileName, data);
  };

  const handleSubmit = () => {
    setEditCampaignError('');

    if (
      !isFile ||
      !campaignName ||
      !retryMaxNumberValue ||
      !retriesPerDayValue ||
      !retryIntervalInMinutesValue
    ) {
      if (!isFile) {
        setListError('Add phone numbers list');
      }

      if (!campaignName) {
        setCampaignNameError('Add campaign name');
      }

      if (!retryMaxNumberValue) {
        setRetryMaxNumberError('Add overall retry attempts');
      }

      if (!retriesPerDayValue) {
        setRetriesPerDayError('Add retries per day');
      }

      if (!retryIntervalInMinutesValue) {
        setRetryIntervalInMinutesError('Add retry delay');
      }
    } else {
      onSubmit();
    }

    if (!editCampaignError) {
      setEditCampaignError('');
    }
  };

  const transformToDDMMYYYY = (timestamp: string) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
      .format(date)
      .replace(/\//g, '.');
  };

  let mastecheValues = { agentName, templateTextFieldValue, campaignName };

  const getStatusColor = (
    status: CampaignRunRecipientStatusType,
  ): 'success' | 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'warning' => {
    if (status === 'pending') return 'default';
    if (status === 'failed') return 'error';
    if (status === 'calling') return 'warning';
    if (status === 'processing') return 'info';
    if (status === 'completed') return 'success';

    return 'default';
  };

  if (loadingFlag || responseLoadingFlag) {
    return <Spinner />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!campaign) {
    return <Spinner />;
  }

  return (
    <>
      <PageContainer>
        <Breadcrumb
          title={t('campaignPage.header.campaignName', { campaignName: 'Call Agent' })}
          items={BCrumb}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BlankCard>
              <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
                <Tabs
                  value={tabIdx}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example">
                  <Tab
                    iconPosition="start"
                    icon={<CampaignOutlinedIcon />}
                    label="Campaign Details"
                    {...a11yProps(0)}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<LocalPhoneOutlinedIcon />}
                    label="Ongoing Calls"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <Divider />
              <CardContent>
                <TabPanel value={tabIdx} index={0}>
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <CardContent>
                          <CustomFormLabel
                            sx={{
                              mt: 0,
                            }}
                            htmlFor="campaign-name">
                            {t('campaignPage.campaignName')}:
                          </CustomFormLabel>
                          <CustomTextField
                            control={control}
                            name="title"
                            onChange={handleOnChangeInput}
                            velue={campaignName}
                            id="campaign-title"
                            variant="outlined"
                            fullWidth
                            required
                            defaultValue={campaign?.title}
                          />
                          {!campaignName && (
                            <Typography component="p" sx={{ color: '#FF0000', width: '300px' }}>
                              {campaignNameError}
                            </Typography>
                          )}

                          <CustomFormLabel htmlFor="campaign-agent">
                            {t('campaignPage.selectAgent')}:
                          </CustomFormLabel>
                          <FormControl fullWidth variant="outlined">
                            {/* <InputLabel
                            id="campaign-agent"
                            sx={{
                              top: '-5px',
                              '&.MuiInputLabel-shrink': {
                                top: '0px',
                              },
                            }}>
                            Agent
                          </InputLabel> */}
                            <CustomSelect
                              control={control}
                              name="agentId"
                              labelId="campaign-agent"
                              onChange={handleAgentChange}
                              id="campaign-agent-id"
                              value={agent}>
                              {agentList &&
                                agentList.map((agent, index) => (
                                  <MenuItem key={index} value={agent.id}>
                                    {agent.name}
                                  </MenuItem>
                                ))}
                            </CustomSelect>
                          </FormControl>

                          <CustomFormLabel htmlFor="campaign-agent">
                            {t('campaignPage.numberManage')}:
                          </CustomFormLabel>

                          {fileUploadingProgress != 0 ? (
                            <>
                              <LinearProgress variant="determinate" value={fileUploadingProgress} />
                            </>
                          ) : (
                            ''
                          )}

                          <ReactSortable
                            list={fileDataArray}
                            setList={(newlist) => setFileDataArray(newlist)}
                            ghostClass={classes.dropArea}
                            handle=".dragHandle"
                            filter=".ignoreDrag"
                            preventOnFilter={true}
                            className={classes.gridContainer}
                            onEnd={({ oldIndex, newIndex }) => onDragDropEnds(oldIndex, newIndex)}>
                            <>
                              {fileDataArray &&
                                fileDataArray.map((row) => {
                                  return (
                                    <Grid className="dragHandle" key={row.id} item>
                                      <Tooltip title={row.fileName}>
                                        <Chip
                                          sx={{
                                            color: '#7C8FAC',
                                            width: '112px',
                                          }}
                                          icon={
                                            <IconFile
                                              color="#7C8FAC"
                                              style={{
                                                fontSize: '24px',
                                              }}
                                            />
                                          }
                                          label={
                                            <div
                                              style={{
                                                width: '46px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                              }}>
                                              {row.fileName}
                                            </div>
                                          }
                                          variant="outlined"
                                          onClick={() =>
                                            handleDownload(row.fileName, row.columns.phone)
                                          }
                                          onDelete={() => handleDeleteList(row.id, row.fileName)}
                                        />
                                      </Tooltip>
                                    </Grid>
                                  );
                                })}
                              <Grid item>
                                <input
                                  ref={inputFile}
                                  type="file"
                                  style={{ display: 'none' }}
                                  accept=".xlsx,.xls"
                                  onChange={handleFileChange}
                                />
                                <Button
                                  onClick={() =>
                                    handleAddFileData().then(() => setExcelFieldError(''))
                                  }
                                  startIcon={<IconPlus />}
                                  sx={{ marginBottom: '10px', borderRadius: '100px' }}>
                                  {t('campaignPage.addList')}
                                </Button>
                                {listError && (
                                  <Typography
                                    component="p"
                                    sx={{ color: '#FF0000', width: '300px' }}>
                                    {listError}
                                  </Typography>
                                )}
                              </Grid>
                            </>
                          </ReactSortable>
                          {excelFieldError && (
                            <Typography
                              component="p"
                              sx={{ color: '#FF0000', whiteSpace: 'pre-wrap' }}>
                              {excelFieldError}
                            </Typography>
                          )}
                        </CardContent>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <CardContent>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              color: '#7C8FAC',
                              marginTop: '0px',
                              marginBottom: '20px',
                            }}>
                            {t('campaignPage.callSetting')}
                          </Typography>
                          <Button
                            onClick={() => setShowScheduleDialogFlag(true)}
                            startIcon={<IconCalendarEvent />}
                            variant="contained">
                            {t('campaignPage.changeSchedule')}
                          </Button>

                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel htmlFor="campaign-retry-attempts">
                                {t('campaignPage.overallRetryAttempt')}:
                              </CustomFormLabel>
                              <CustomTextField
                                control={control}
                                name="retryMaxNumber"
                                onChange={handleOnChangeInput}
                                type="text"
                                id="campaign-retry-attempts"
                                placeholder="Enter overall retry attempts"
                                fullWidth
                                value={retryMaxNumberValue}
                                // defaultValue={campaign?.retryMaxNumber}
                              />
                              {retryMaxNumberError && (
                                <Typography
                                  component="p"
                                  sx={{ color: '#FF0000', maxWidth: '300px' }}>
                                  {retryMaxNumberError}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel htmlFor="campaign-retry-day">
                                {t('campaignPage.retryPerDay')}:
                              </CustomFormLabel>
                              <CustomTextField
                                control={control}
                                name="retriesPerDay"
                                onChange={handleOnChangeInput}
                                type="text"
                                id="campaign-retry-day"
                                placeholder="Enter retries per day"
                                fullWidth
                                value={retriesPerDayValue}
                                // defaultValue={campaign?.retriesPerDay}
                              />
                              {retriesPerDayError && (
                                <Typography
                                  component="p"
                                  sx={{ color: '#FF0000', maxWidth: '300px' }}>
                                  {retriesPerDayError}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>

                          <CustomFormLabel htmlFor="campaign-retry-delay">
                            {t('campaignPage.retryDelay')}
                          </CustomFormLabel>
                          <CustomTextField
                            control={control}
                            name="retryIntervalInMinutes"
                            onChange={handleOnChangeInput}
                            type="text"
                            id="campaign-retry-delay"
                            placeholder="Enter retries delay"
                            fullWidth
                            value={retryIntervalInMinutesValue}
                            // defaultValue={campaign?.retryIntervalInMinutes}
                          />
                          {retryIntervalInMinutesError && (
                            <Typography component="p" sx={{ color: '#FF0000', maxWidth: '300px' }}>
                              {retryIntervalInMinutesError}
                            </Typography>
                          )}
                        </CardContent>
                      </Grid>
                    </Grid>
                    <CampaignTextField
                      mastecheValues={mastecheValues}
                      setTemplateTextFieldValue={setTemplateTextFieldValue}
                      setValue={setValue}
                      control={control}
                    />
                    <CardContent>
                      <Stack
                        sx={{ marginTop: '30px' }}
                        direction="row"
                        justifyContent="space-between">
                        <Stack direction="row" spacing="20px">
                          <CampaignStatusButton
                            onClick={handleManagedStartStop}
                            disabled={isRunningLoading || isStoppingLoading}
                            campaign={campaignData}
                            statusLabels={{
                              startText: t('adminMainPage.operation.startCampaign'),
                              stopText: t('adminMainPage.operation.stopCampaign'),
                            }}
                          />
                          <Button
                            onClick={() => {
                              handleDeleteBtn(campaignId);
                            }}
                            variant="outlined"
                            color="error"
                            startIcon={<IconTrash size={24} />}>
                            {t('action.delete')}
                          </Button>
                        </Stack>
                        <Stack direction="row" justifyContent="space-around" spacing="20px">
                          <Button onClick={handleSubmit} variant="contained" color="primary">
                            {t('action.save')}
                          </Button>
                          <Button onClick={() => navigator(AuthenticatedPath.CAMPAIGN)}>
                            Cancel
                          </Button>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </form>
                </TabPanel>
                <TabPanel value={tabIdx} index={1}>
                  {calls.length ? (
                    <TableContainer>
                      <Table>
                        <TableHead className={classes.tableheahder}>
                          <TableRow>
                            <TableCell
                              onClick={handleSortPhoneNumber}
                              className={classes.table_first_cell}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.phoneNumber')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell onClick={handleSortDate}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.startDate')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.status')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell onClick={handleSortDuration}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.callDuration')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell onClick={handleSortSentiment}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.sentimentIndi')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>

                            <TableCell className={classes.table_last_cell}>
                              <Typography variant="h6" className={classes.table_th} align="right">
                                {t('campaignPage.action')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {calls.map((row) => {
                            return (
                              <TableRow className={classes.table_row} key={row.id} hover>
                                <TableCell align="left" className={classes.table_first_cell}>
                                  <Typography variant="subtitle1">{row.phoneNumber}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {!row.startDate ? (
                                    <Chip label={t('campaignPage.unavailable')} />
                                  ) : (
                                    <Typography variant="subtitle1">
                                      {transformToDDMMYYYY(row.startDate) || row.status}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  <Chip
                                    label={capitalize(row.status)}
                                    color={getStatusColor(row.status)}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Stack direction="row" alignItems="center" spacing="10px">
                                    {!row.callDuration && !row.startDate ? (
                                      <>
                                        <IconClockHour5 />
                                        <Chip label={t('campaignPage.unavailable')} />
                                      </>
                                    ) : (
                                      <Typography variant="subtitle1">
                                        {row.callDuration ? (
                                          <>{row.callDuration} </>
                                        ) : (
                                          <Chip label={t('campaignPage.unavailable')} />
                                        )}
                                      </Typography>
                                    )}
                                  </Stack>
                                </TableCell>
                                <TableCell align="center">
                                  {!row.sentiments.length ? (
                                    <Chip
                                      style={{ position: 'relative', right: 50 }}
                                      label={t('campaignPage.unavailable')}
                                    />
                                  ) : (
                                    <IconFlag3 color="green" />
                                  )}
                                  {/* {row.sentiments.length === 1 ? (
                                  <IconFlag3 color="green" />
                                ) : row.sentiments.length === 2 ? (
                                  <IconFlag3 color="orange" />
                                ) : (
                                  <IconFlag3 color="red" />
                                )} */}
                                </TableCell>
                                <TableCell align="right" sx={{ paddingRight: 0 }}>
                                  <Button
                                    onClick={() => setShowMonitorDialogFlag(true)}
                                    variant="outlined"
                                    startIcon={<IconHeadphones />}>
                                    {t('action.monitor')}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography sx={{ textAlign: 'center' }}>
                      {t('campaignPage.emptyOngoingCalls')}
                    </Typography>
                  )}
                  {/* <Stack
                    marginTop="77px"
                    direction="row"
                    justifyContent="space-between">
                    <Stack direction="row" spacing="10px">
                      <Box width="170px">
                        <Typography fontSize="14px" color="#5A6A85">
                          {t('campaignPage.averageDuration')}
                        </Typography>
                        <Typography fontSize="16px" color="#2A3547">
                          00:50:12
                        </Typography>
                      </Box>
                      <Button variant="outlined" startIcon={<IconRefresh />}>
                        {t('action.reset')}
                      </Button>
                    </Stack>
                    <Button variant="outlined">Close</Button>
                  </Stack> */}
                </TabPanel>
              </CardContent>
            </BlankCard>
          </Grid>
        </Grid>
        <Snackbar
          open={snackBarShow}
          autoHideDuration={2000}
          onClose={() => setSnackBarShow(false)}
          message={`${countOfPhoneNumbers} out of ${countOfPhoneNumbers} numbers were extracted`}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
        <MonitorCampaignPopup showDialogFlag={showMonitorDialogFlag} closeDialog={closeDialog} />
        <SchedulePopup
          showDialogFlag={showScheduleDilaogFlag}
          closeDialog={closeDialog}
          schedule={schedule}
          setSchedule={setSchedule}
        />

        <ConfirmDeleteDialog
          open={listDeleteDialog}
          onClose={setListDeleteDialog}
          dialogTitle={'dialog.title1'}
          dialogContentText={'dialog.content'}
          showDialog={setListDeleteDialog}
          confirmDelete={deletFileData}
        />

        <ConfirmDeleteDialog
          open={showDialog}
          onClose={setShowDialog}
          dialogTitle={'dialog.title3'}
          dialogContentText={'dialog.content3'}
          showDialog={setShowDialog}
          confirmDelete={deleteCampagin}
        />
      </PageContainer>
      {editCampaignError ? <CustomAlert message={editCampaignError} type={'error'} /> : ''}
      {fileError ? <CustomAlert message={fileError} type={'error'} /> : ''}
    </>
  );
};
